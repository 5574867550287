// Routes
export const UNSUBSCRIBE_API_ROUTE = '/api/unsubscribe';
export const EKOMI_WIDGET_API_ROUTE = '/api/ekomi/component/';
export const EKOMI_TESTIMONIALS_API_ROUTE = '/api/ekomi/testimonials/';

// Unsubscribe params
export const ALLOWED_UNSUBSCRIBE_CHANNELS = ['email', 'sms', 'messenger'];
export const ALLOWED_UNSUBSCRIBE_LEVELS = [
  'transactional',
  'marketing',
  'operational',
];

export const ALLOWED_TOKEN_FORMAT = '^[A-Za-z0-9-]{1,36}$';
