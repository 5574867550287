export const EKOMI_SEAL_WIDTH = 74;
export const EKOMI_SEAL_WIDTH_SMALL = 48;
export const EKOMI_MAX_RATING = 5;

export const EKOMI_PAGE_URL =
  'https://www.ekomi.de/bewertungen-finanzcheck.html';

export const EKOMI_SEAL_BRONZE =
  'https://images.ctfassets.net/f6c1mtmq4qrb/78hg9n6YUJhsQyBCEcCnvF/7ab3060bc4fe80ecc1b92bc9ebeb556a/ekomi_bronze.png?fm=webp';
export const EKOMI_SEAL_SILVER =
  'https://images.ctfassets.net/f6c1mtmq4qrb/79UYXG30qrDAXaNgPzWywR/12788930e5d8d9c62088eb35225e2197/ekomi_share_silber_seal.png?fm=webp';
export const EKOMI_SEAL_GOLD =
  'https://images.ctfassets.net/f6c1mtmq4qrb/1vku8b9kYLXIM2BgF4GUth/88bc945a8f344bb1780df414313b8e8f/eKomi_pagebuilder_2020.png?fm=webp';

export const EKOMI_STAR_URL_FULL =
  'https://finanzcheck.de/static/icons/star.svg';
export const EKOMI_STAR_URL_EMPTY =
  'https://finanzcheck.de/static/icons/star_empty.svg';
export const EKOMI_STAR_SIZE = 16;
