import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

import { EkomiTestimonials } from './EkomiTestimonials';
import { EkomiSealWithRatings } from './EkomiSealWithRatings';

const SliderWrapper = styled('div')`
  margin: 0 auto;
  width: 100%;
`;

const SealWrapper = styled('div')`
  width: 100%;
  margin: 0 auto 32px;
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
`;

interface CustomerRatingProps {
  headline: string;
}

export const CustomerRating = (props: CustomerRatingProps) => {
  const { headline } = props;

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h3"
        color="text.blue"
        mt={2}
        pt={4}
        pl={{ xs: 1, sm: 5, md: 8 }}
      >
        {headline}
      </Typography>
      <SliderWrapper>
        <EkomiTestimonials />
      </SliderWrapper>
      <SealWrapper>
        <EkomiSealWithRatings />
      </SealWrapper>
    </Container>
  );
};
