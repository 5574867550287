import { styled } from '@mui/material/styles';

import { CustomerRating } from './CustomerRating';

import type { ICeEkomi } from 'types/generated/contentful';

const EkomiBackground = styled('div')(({ theme }) => ({
  // @ts-ignore:next-line
  backgroundColor: theme.palette.background.blue050,
}));

export interface EkomiProps extends ICeEkomi {
  headline: string;
}

export const Ekomi = (props: EkomiProps) => {
  const { headline = 'Das sagen unsere Kunden' } = props;

  return (
    <EkomiBackground>
      <CustomerRating headline={headline} />
    </EkomiBackground>
  );
};
