import useSWR from 'swr';
import { styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';

import { Slider } from './Slider';
import { Testimonial } from './Testimonial';
import { EKOMI_TESTIMONIALS_API_ROUTE } from 'lib/constants/api';

import type { Testimonial as TestimonialType } from 'types/ekomi';

const SliderContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export const EkomiTestimonials = () => {
  const { data, error, isLoading } = useSWR(
    EKOMI_TESTIMONIALS_API_ROUTE,
    fetcher,
  );

  if (isLoading || error) return null;

  const hasTestimonials = data && data.length !== 0;
  if (!hasTestimonials) return null;

  const testimonials = data as TestimonialType[];

  return (
    <Box py={1}>
      <SliderContainer maxWidth="lg">
        <Slider>
          {testimonials.map((testimonial) => (
            <Testimonial key={testimonial.submitted} data={testimonial} />
          ))}
        </Slider>
      </SliderContainer>
    </Box>
  );
};
